import { createContext, useEffect, useState, useMemo } from "react";
import Cookies from "js-cookie";
import useUserRoleProductType from "app/hooks/user/useUserRoleProductType";

// Create context with initial default values
const AuthContext = createContext({
  method: "JWT",
  login: () => {},
  logout: () => {},
  userRoleProductType: {},
});

export const AuthProvider = ({ children }) => {
  // Initialize authentication state based on the presence of a token
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = Cookies.get("token");
    console.log("Initial token:", token);
    return !!token;
  });

  const { data: userRoleProductType } = useUserRoleProductType();

  useEffect(() => {
    const token = Cookies.get("token");
    console.log("Token in useEffect:", token);

    // Optional: You can add token validation logic here if needed (e.g., decode the token or check its expiration).
    setIsAuthenticated(!!token);
  }, []);

  const login = (token) => {
    console.log("Logging in with token:", token);
    Cookies.set("token", token, { sameSite: "Lax", secure: true });
    setIsAuthenticated(true);
  };

  const logout = () => {
    console.log("Logging out");
    Cookies.remove("token");
    setIsAuthenticated(false);
  };

  // Memoize the context value to prevent unnecessary re-renders
  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      login,
      logout,
      userRoleProductType,
    }),
    [isAuthenticated, userRoleProductType]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
