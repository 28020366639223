import { Navigate, useLocation } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
export default function AuthGuard({ children, auth }) {
  const { userRolePRoductType, isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  if (!isAuthenticated) {
    return <Navigate replace to="/signin" state={{ from: pathname }} />;
  }
  // console.log("userRolePRoductType", userRolePRoductType.role);
  // // Check if the user's role is authorized for this route
  // if (auth && !auth.includes(userRolePRoductType?.role)) {
  //   return <Navigate to="/403" />;
  // }
  // // Check if the user has an active subscription
  // if (!userRolePRoductType.is_subscription) {
  //   return <Navigate to="/plans" />;
  // }
  return <>{children}</>;
}
