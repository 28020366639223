import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MatxTheme } from "./components";
// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
import { Alert, Snackbar } from "@mui/material";
// ROUTES
import routes from "./routes";
import SnackbarProvider from "./components/SnackbarProvider/SnackbarProvider";
import createEmotionCache from "./createEmotionCache";
import { useState } from "react";
import { CacheProvider } from "@emotion/react";
import { CartProvider } from "./views/inventory/common/CartContext";



const clientSideEmotionCache = createEmotionCache();
const queryQlient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

export default function App(props) {
  const content = useRoutes(routes);
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  function handleClose() {
    setIsOpen(false);
    setSnackbarMsg("");
  }
  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <CacheProvider value={emotionCache}>
      <QueryClientProvider client={queryQlient}>
        <SnackbarProvider>
          <SettingsProvider>
            <AuthProvider>
            <CartProvider> 
                <MatxTheme>
                  <CssBaseline />
                  {content}
                </MatxTheme>
              </CartProvider>
            </AuthProvider>
          </SettingsProvider>
        </SnackbarProvider>
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="filled"
            severity={"error"}
            sx={{ width: "100%", fontWeight: 500 }}
          >
            {snackbarMsg}
          </Alert>
        </Snackbar>
      </QueryClientProvider>
    </CacheProvider>
  );
}
