import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";
import { element } from "prop-types";
import { Lazy } from "yup";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);
const EmailVarified = Loadable(
  lazy(() => import("app/views/sessions/EmailVarified"))
);

// LOAN PAGES
const LoanLead = Loadable(
  lazy(() => import("app/views/material-kit/loan/AddLoan"))
);

// PROPERTY PAGES
const PropertyAgent = Loadable(
  lazy(() => import("app/views/property/property-agent"))
);
const PropertyDetails = Loadable(
  lazy(() => import("app/views/property/property-details"))
);
const PropertyList = Loadable(
  lazy(() => import("app/views/property/property-list"))
);
const PropertyReports = Loadable(
  lazy(() => import("app/views/property/property-reports"))
);

// CAR PAGES
const CarDashboard = Loadable(lazy(() => import("app/views/car/CarDashboard")));
const CarInquiry = Loadable(
  lazy(() => import("app/views/car-website/componants/CarInquiryGrid"))
);
const CarDetails = Loadable(lazy(() => import("app/views/car/CarDetails")));
const CarTestimonialForm = Loadable(
  lazy(() => import("app/views/car-website/componants/CarTestimonialGrid"))
);
const CarLoanInquiry = Loadable(lazy(() => import("app/views/car/CarLoan")));
const CarTestimonials = Loadable(
  lazy(() => import("app/views/car/Testimonials"))
);
const CarAgents = Loadable(
  lazy(() => import("app/views/car/agents/CarAgentList"))
);
const CarBilling = Loadable(lazy(() => import("app/views/car/CarBilling")));
const CarWebsite = Loadable(
  lazy(() => import("app/views/car-website/website"))
);
const CarSell = Loadable(lazy(() => import("app/views/car-website/CarSell")));
const CarWebsiteHome = Loadable(
  lazy(() => import("app/views/car-website/WebSiteHome"))
);
const CarMasterWebsite = Loadable(
  lazy(() => import("app/views/car-website/matser-website"))
);
const CarContact = Loadable(
  lazy(() => import("app/views/car-website/componants/CarContact"))
);
const CarDetail = Loadable(
  lazy(() => import("app/views/car-website/componants/CarDetails"))
);
const CarSignIn = Loadable(
  lazy(() => import("app/views/car-website/componants/CarSignIn"))
);
const CarSignUp = Loadable(
  lazy(() => import("app/views/car-website/componants/CarSignUp"))
);
const CarRecommendation = Loadable(
  lazy(() => import("app/views/car-website/componants/CarRecomandation"))
);
const CarInfo = Loadable(
  lazy(() => import("app/views/car-website/componants/CarInfo"))
);
const CarAbout = Loadable(
  lazy(() => import("app/views/car-website/componants/CarAbout"))
);
const CarFiletrs = Loadable(
  lazy(() => import("app/views/car-website/componants/CarFilters"))
);
const PaymentConfirmation = Loadable(
  lazy(() => import("app/views/car/CarPaymentDataGridHeder"))
);

const Team = Loadable(lazy(() => import("app/views/team/form/TeamList")));
const BillingReport = Loadable(
  lazy(() => import("app/views/billing/componants/BillingReport"))
);
const CarBillingSubscription = Loadable(
  lazy(() => import("app/views/car/CarBillingSubscription"))
);

//Contact
const ContactDataGrid = Loadable(
  lazy(() => import("app/views/contact/components/ContactDataGrid"))
);

const Plan = Loadable(lazy(() => import("app/views/plan/plan/Plan")));
// E-CHART PAGE
const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
);
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
// AGENTS PAGE
const Agents = Loadable(lazy(() => import("app/views/agent/Agents")));
// Loan PAGE
const Loan = Loadable(lazy(() => import("app/views/loan/Loan")));
const LoanBlockedFiles = Loadable(
  lazy(() => import("app/views/loan/LoanBlockedFile"))
);
const Collaboration = Loadable(
  lazy(() => import("app/views/loan/Collaboration"))
);

const LoanWebsite = Loadable(
  lazy(() => import("app/views/loan/loanWebsite/LoanWebsite"))
);
const LoanTestimonials = Loadable(
  lazy(() => import("app/views/loan/loanFooter/LoanTestimonials"))
);
const LoanAboutUs = Loadable(
  lazy(() => import("app/views/loan/loanFooter/LoanAboutUs"))
)
const LoanFAQs = Loadable(
  lazy(() => import("app/views/loan/loanFooter/LoanFAQs"))
)

const LoanContactUs = Loadable(
  lazy(() => import("app/views/loan/loanFooter/LoanContactUs"))
)

const LoanAgentsInfo = Loadable(
  lazy(() => import("app/views/loan/other/LoanAgentsInfo"))
);
// Commision PAGE
const Commision = Loadable(
  lazy(() => import("app/views/commission/Commision"))
);
const CommisionInPercents = Loadable(
  lazy(() => import("app/views/commission/CommisionInPercent"))
);
const CommisionOfAgents = Loadable(
  lazy(() => import("app/views/commission/CommisionOfAgents"))
);
// Media PAGE
const Media = Loadable(lazy(() => import("app/views/media/Media")));
// Email Varified PAGE
//Admin Billing Pages
const AdminBilling = Loadable(
  lazy(() => import("app/views/billing/componants/BillingDetails"))
);
//Website page
const WebSiteTestimonials = Loadable(
  lazy(() => import("app/views/car-website/WebSiteTestimonials"))
);

//inventory page
const Dashboard = Loadable(
  lazy(() => import("app/views/inventory/dashboard/componants/dashboard"))
);
const Website = Loadable(
  lazy(() => import("app/views/inventory/dashboard/componants/website"))
);
// const Website = Loadable(
//   lazy(() => import("app/views/inventory/dashboard/componants/website"))
// );
const TotalStockList = Loadable(
  lazy(() => import("app/views/inventory/dashboard/componants/StockList"))
);
const TotalOrderList = Loadable(
  lazy(() => import("app/views/inventory/dashboard/componants/OrderList"))
);
const SalesList = Loadable(
  lazy(() => import("app/views/inventory/dashboard/componants/SalesList"))
);
const RevenueList = Loadable(
  lazy(() => import("app/views/inventory/dashboard/componants/SubCategoryList"))
);

// const CategoryList = Loadable(lazy(() => import("app/views/inventory/category/form/CategoryList")));
// const CustomerList = Loadable(lazy(() => import("app/views/inventory/customer/form/CustomerList")));
// const ProductList = Loadable(lazy(() => import("app/views/inventory/product/form/ProductList")));
// const StockInList = Loadable(lazy(() => import("app/views/inventory/stock/componants/StockIn")));
// const StockOutList = Loadable(lazy(() => import("app/views/inventory/stock/componants/StockOut")));
// const ProductStockList = Loadable(
//   lazy(() => import("app/views/inventory/dashboard/componants/ProductList"))
// );
// const SupplierList = Loadable(lazy(() => import("app/views/inventory/supplier/form/SupplierList")));
// const Invoice = Loadable(lazy(() => import("app/views/inventory/billing/componant/Invoice")));
const SubCategoryList = Loadable(
  lazy(() => import("app/views/inventory/subcategory/form/SubCategoryList"))
);
const CategoryList = Loadable(
  lazy(() => import("app/views/inventory/category/form/CategoryList"))
);
const CustomerList = Loadable(
  lazy(() => import("app/views/inventory/customer/form/CustomerList"))
);
const ProductList = Loadable(
  lazy(() => import("app/views/inventory/product/form/ProductList"))
);
const StockInList = Loadable(
  lazy(() => import("app/views/inventory/stock/componants/StockIn"))
);
const StockOutList = Loadable(
  lazy(() => import("app/views/inventory/stock/componants/StockOut"))
);
const ProductStockList = Loadable(
  lazy(() => import("app/views/inventory/dashboard/componants/ProductList"))
);
const SupplierList = Loadable(
  lazy(() => import("app/views/inventory/supplier/form/SupplierList"))
);
const Invoice = Loadable(
  lazy(() => import("app/views/inventory/billing/componant/Invoice"))
);
const InventoryWebsite = Loadable(
  lazy(() => import("app/views/inventory/inventoryWebsite/inventoryWebsite"))
);
const InventoryFilter = Loadable(
  lazy(() => import("app/views/inventory/inventoryFilter/InventoryFilter"))
);
const InventoryAbout = Loadable(
  lazy(() => import("app/views/inventory/inventoryOther/InventoryAbout"))
);
const InventoryContact = Loadable(
  lazy(() => import("app/views/inventory/inventoryOther/InventoryContact"))
);
const InventoryLogin = Loadable(
  lazy(() => import("app/views/inventory/inventoryOther/InventoryLogin"))
);
const InventoryCart = Loadable(
  lazy(() => import("app/views/inventory/inventoryOther/InventoryCart"))
);
const InventoryInfo = Loadable(
  lazy(() => import("app/views/inventory/inventoryInfo/InventoryInfo"))
);
const InventoryTandC = Loadable(
  lazy(() => import("app/views/inventory/inventoryOther/InventoryTandC"))
);
const InventoryPrivacyPolicy = Loadable(
  lazy(() =>
    import("app/views/inventory/inventoryOther/InventoryPrivacyPolicy")
  )
);

const AdvertiseList = Loadable(
  lazy(() => import("app/views/inventory/advertise/form/AdvertiseList"))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      { path: "/", element: <Analytics />, auth: authRoles.admin },
      // Agents route
      { path: "/loan/agent", element: <Agents />, auth: authRoles.admin },
      // Loan route
      { path: "/loan/details", element: <Loan />, auth: authRoles.admin },
      {
        path: "/loan/blocks",
        element: <LoanBlockedFiles />,
        auth: authRoles.admin,
      },

      { path: "/contact", element: <ContactDataGrid />, auth: authRoles.admin },

      // Add Lead
      { path: "/loan/add-lead", element: <LoanLead />, auth: authRoles.admin },
      // Commision route
      {
        path: "/loan/comission",
        element: <Commision />,
        auth: authRoles.admin,
      },
      {
        path: "/loan/comission-agents",
        element: <CommisionOfAgents />,
        auth: authRoles.admin,
      },
      {
        path: "/loan/comission-percents",
        element: <CommisionInPercents />,
        auth: authRoles.admin,
      },
      {
        path: "/loan/blocked-files",
        element: <CommisionInPercents />,
        auth: authRoles.admin,
      },
      // Commision route
      { path: "/loan/billing", element: <Commision />, auth: authRoles.admin },
      // Commision route
      { path: "/media", element: <Media />, auth: authRoles.admin },
      // e-chart route
      {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor,
      },
      // Collaboration
      {
        path: "/loan/collaboration",
        element: <Collaboration />,
        auth: authRoles.admin,
      },
    ],
  },

  { path: "/loan/home-page", element: <LoanWebsite /> },
  { path: "/loan/testimonials", element: <LoanTestimonials /> },
  { path: "/loan/agents-info", element: <LoanAgentsInfo /> },
  { path: "/loan/about", element: <LoanAboutUs /> },
  { path: "/loan/faq", element: <LoanFAQs /> },
  { path: "/loan/contact", element: <LoanContactUs /> },

  // session pages route
  { path: "/404", element: <NotFound /> },
  { path: "/signin", element: <JwtLogin /> },
  { path: "/signup", element: <JwtRegister /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/verify", element: <EmailVarified /> },

  // generic pages route
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      {
        path: "/property/property-reports",
        element: <PropertyReports />,
        auth: authRoles.admin,
      },
      {
        path: "/property/property-details",
        element: <PropertyDetails />,
        auth: authRoles.admin,
      },
      {
        path: "/property/property-agent",
        element: <PropertyAgent />,
        auth: authRoles.admin,
      },
      {
        path: "/property/property-list",
        element: <PropertyList />,
        auth: authRoles.admin,
      },
      {
        path: "/property/billing",
        element: <PropertyList />,
        auth: authRoles.admin,
      },
    ],
  },

  // property pages route
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      {
        path: "/property/property-reports",
        element: <PropertyReports />,
        auth: authRoles.admin,
      },
      {
        path: "/property/property-details",
        element: <PropertyDetails />,
        auth: authRoles.admin,
      },
      {
        path: "/property/property-agent",
        element: <PropertyAgent />,
        auth: authRoles.admin,
      },
      {
        path: "/property/property-list",
        element: <PropertyList />,
        auth: authRoles.admin,
      },
      {
        path: "/property/billing",
        element: <PropertyList />,
        auth: authRoles.admin,
      },
    ],
  },
  // inventory page route
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      { path: "dashboard", element: <Dashboard />, auth: authRoles.admin },
      { path: "website", element: <Website /> },
      {
        path: "product-stock",
        element: <ProductStockList />,
        auth: authRoles.admin,
      },
      { path: "stock-in", element: <StockInList />, auth: authRoles.admin },
      { path: "stock-out", element: <StockOutList />, auth: authRoles.admin },
      { path: "advertise", element: <AdvertiseList />, auth: authRoles.admin },
      { path: "category", element: <CategoryList />, auth: authRoles.admin },
      {
        path: "subcategory",
        element: <SubCategoryList />,
        auth: authRoles.admin,
      },
      { path: "customer", element: <CustomerList />, auth: authRoles.admin },
      { path: "product", element: <ProductList />, auth: authRoles.admin },
      { path: "supplier", element: <SupplierList />, auth: authRoles.admin },
      { path: "invoice", element: <Invoice />, auth: authRoles.admin },
      {
        path: "total-stock",
        element: <TotalStockList />,
        auth: authRoles.admin,
      },
      {
        path: "total-order",
        element: <TotalOrderList />,
        auth: authRoles.admin,
      },
      { path: "sublist", element: <RevenueList />, auth: authRoles.admin },
      { path: "sales-list", element: <SalesList />, auth: authRoles.admin },
      { path: "summary", element: <TotalOrderList />, auth: authRoles.admin },
    ],
  },
  { path: "/inventory/website-home/", element: <InventoryWebsite /> },
  { path: "/inventory/filter/", element: <InventoryFilter /> },
  { path: "/inventory/about", element: <InventoryAbout /> },
  { path: "/inventory/contact/", element: <InventoryContact /> },
  { path: "/inventory/login/", element: <InventoryLogin /> },
  { path: "/inventory/cart/", element: <InventoryCart /> },
  { path: "/inventory/Info/", element: <InventoryInfo /> },
  { path: "/inventory/t&c/", element: <InventoryTandC /> },
  { path: "/inventory/privacy-policy/", element: <InventoryPrivacyPolicy /> },

  // cars pages route
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      {
        path: "/car/dashboard",
        element: <CarDashboard />,
        auth: authRoles.admin,
      },
      { path: "/car/showroom", element: <CarDetails />, auth: authRoles.admin },
      {
        path: "testimonials",
        element: <CarTestimonialForm />,
        auth: authRoles.admin,
      },
      { path: "/car/inquiry", element: <CarInquiry />, auth: authRoles.admin },
      { path: "plans", element: <CarBilling />, auth: authRoles.admin },
      { path: "/car/filters", element: <CarFiletrs />, auth: authRoles.admin },
      {
        path: "/payment-confirmation",
        element: <PaymentConfirmation />,
        auth: authRoles.admin,
      },
      { path: "agents", element: <CarAgents />, auth: authRoles.admin },
      { path: "billing", element: <AdminBilling />, auth: authRoles.admin },

      {
        path: "bill/report",
        element: <BillingReport />,
        auth: authRoles.admin,
      },
      { path: "team", element: <Team />, auth: authRoles.admin },
    ],
  },
  { path: "/", element: <Navigate to="/" /> },
  { path: "*", element: <CarLoanInquiry /> },
  { path: "/car/website", element: <CarWebsite /> },
  { path: "/car/website-home", element: <CarWebsiteHome /> },
  { path: "/car/websitetestimonials", element: <WebSiteTestimonials /> },
  { path: "/car/sellcar", element: <CarSell /> },
  { path: "/car/master-website", element: <CarMasterWebsite /> },
  { path: "plans", element: <Plan /> },
  { path: "/car/contact", element: <CarContact /> },
  { path: "/car/details", element: <CarDetail /> },
  { path: "/car/reccomdation", element: <CarRecommendation /> },
  { path: "/car/info", element: <CarInfo /> },
  { path: "/car/car-about", element: <CarAbout /> },
  { path: "/car/signin", element: <CarSignIn /> },
  { path: "/car/signup", element: <CarSignUp /> },
  {
    path: "/carbillingsubscription",
    element: <CarBillingSubscription />,
    auth: authRoles.admin,
  },
];

export default routes;
