import Axios from "axios";
import Cookies from "js-cookie";

//console.log("Base domain", process.env.NEXT_PUBLIC_BACKEND_BASE_DOMAIN);

const serverurl = "https://api.zerodotone.in";
const local = "http://127.0.0.1:8000";

const baseDomain = process.env.NEXT_PUBLIC_BACKEND_BASE_DOMAIN || serverurl;

export const baseURL = baseDomain + "/api";

const axios = Axios.create({
  baseURL,
  headers: {
    Authorization: "Bearer " + Cookies.get("token")
  }
});

export default axios;
